<template>
  <!----->
  <div>
    <v-card>
      <v-card-text>
        <!-- patient info -->
        <v-card>
          <v-card-title>Patient Infomation</v-card-title>
          <v-card-text> form </v-card-text>
        </v-card>

        <!-- patient info -->
        <v-card>
          <v-card-title>Case Infomation</v-card-title>
          <v-card-text> form </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters({
      getAuth: "BFC/getAuth",
      getInfo: "BFC/getDoctorInfo",
    }),
  },
};
</script>
